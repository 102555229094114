:root {
  --link-box-shadow-focus: 0 0 0 2px var(--lp-color-shadow-interactive-focus);
  --subtle-link-color: var(--lp-color-text-ui-secondary);
  --header-background-color: var(--color-bg-card);
  --page-gutter-width: .625rem;
  --layout-sidenav-width: 0;
  --layout-topbar-height: 3rem;
  --layout-topbar-margins: var(--lp-spacing-400);
  --layout-topbar-action-gap: var(--lp-spacing-200);
  --layout-gutter-width: var(--lp-size-20);
  --stacking-behind-default: -1;
  --stacking-default: auto;
  --stacking-new-context: 0;
  --stacking-above-new-context: 1;
  --stacking-z-index-10: 10;
  --stacking-topbar: var(--lp-z-index-300);
  --stacking-app-banner: calc(var(--lp-z-index-100)  + 1);
  --stacking-app-sidebar: var(--lp-z-index-200);
  --stacking-app-entity-sidebar: var(--stacking-app-sidebar);
  --stacking-sticky-header: var(--lp-z-index-100);
  --stacking-experiments-sticky-header: var(--lp-z-index-200);
  --stacking-sticky-manager-controls: var(--lp-z-index-200);
  --stacking-context-target-popover: 9997;
  --stacking-context-trigger-list-item: 10000;
  --stacking-modal-background: 10000;
  --stacking-skip-to-content: 10001;
  --stacking-select-popover: 10002;
  --stacking-context-eval-reason: 30000;
  --stacking-overlay: 100000;
  --stacking-commandbar: 3000000000;
  --stacking-variation-diamonds: var(--lp-z-index-500);
  --ia-area-gap: 2px;
  --ia-grid-unit: var(--lp-spacing-300);
}

:root, [data-theme="default"] {
  --color-bg-card: var(--lp-color-white-950);
  --nav-background-color: var(--lp-color-gray-950);
}

@media screen and (width >= 46.25rem) {
  :root {
    --page-gutter-width: 2.5rem;
    --layout-sidenav-width: 14.375rem;
    --layout-topbar-margins: var(--lp-spacing-500);
    --layout-topbar-action-gap: var(--lp-spacing-300);
  }
}

[data-theme="dark"] {
  --color-bg-card: var(--lp-color-gray-950);
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  background-color: var(--lp-color-bg-ui-primary);
  font-variant-ligatures: no-contextual;
  margin: 0;
}

h1, h2, h3, h4, h5, p, ol, ul, blockquote, figure {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  font-size: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

[tabindex="-1"]:focus {
  outline: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: textfield;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

button {
  -webkit-appearance: button;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: .063em dotted buttontext;
}

a:active:not([class*="_Button"]):not([data-pressed]):not([data-focused]):not([data-disabled]) {
  color: var(--lp-color-text-interactive-active);
  outline: 0;
  text-decoration: underline;
}

:root {
  --lp-asset-font-audimat-3000-regulier-400-normal: Audimat3000-Regulier. var-subset;
  --lp-asset-font-inter-300-800-normal: Inter. var-subset;
  --lp-color-gray-0: #f7f9fb;
  --lp-color-gray-50: #eceff2;
  --lp-color-gray-100: #d8dde3;
  --lp-color-gray-200: #c6cbd1;
  --lp-color-gray-300: #a9afb4;
  --lp-color-gray-400: #898e94;
  --lp-color-gray-500: #6c727a;
  --lp-color-gray-600: #545a62;
  --lp-color-gray-700: #3f454c;
  --lp-color-gray-800: #2d3239;
  --lp-color-gray-900: #23252a;
  --lp-color-gray-950: #181a1f;
  --lp-color-blue-0: #f6f8ff;
  --lp-color-blue-50: #e4eaff;
  --lp-color-blue-100: #cfd9ff;
  --lp-color-blue-200: #afbfff;
  --lp-color-blue-300: #8a9eff;
  --lp-color-blue-400: #7084ff;
  --lp-color-blue-500: #425eff;
  --lp-color-blue-600: #3144d9;
  --lp-color-blue-700: #2536a6;
  --lp-color-blue-800: #19267d;
  --lp-color-blue-900: #121d61;
  --lp-color-blue-950: #0b144d;
  --lp-color-purple-0: #fbf6ff;
  --lp-color-purple-50: #f4e9ff;
  --lp-color-purple-100: #e6d0ff;
  --lp-color-purple-200: #dcb9ff;
  --lp-color-purple-300: #cb91ff;
  --lp-color-purple-400: #bd61ff;
  --lp-color-purple-500: #a636ec;
  --lp-color-purple-600: #8717cd;
  --lp-color-purple-700: #6a00a9;
  --lp-color-purple-800: #4e007e;
  --lp-color-purple-900: #3c0262;
  --lp-color-purple-950: #2e004d;
  --lp-color-green-0: #e5ffe8;
  --lp-color-green-50: #c7fbce;
  --lp-color-green-100: #a3f2b4;
  --lp-color-green-200: #6ce090;
  --lp-color-green-300: #1ecb6a;
  --lp-color-green-400: #00a455;
  --lp-color-green-500: #008344;
  --lp-color-green-600: #006933;
  --lp-color-green-700: #005025;
  --lp-color-green-800: #003b1b;
  --lp-color-green-900: #092c17;
  --lp-color-green-950: #00210d;
  --lp-color-red-0: #fff5f6;
  --lp-color-red-50: #ffe8eb;
  --lp-color-red-100: #ffcdd6;
  --lp-color-red-200: #ffb5bd;
  --lp-color-red-300: #ff8a96;
  --lp-color-red-400: #f84f6e;
  --lp-color-red-500: #db2251;
  --lp-color-red-600: #b6073c;
  --lp-color-red-700: #8c062b;
  --lp-color-red-800: #6b001c;
  --lp-color-red-900: #4e0b16;
  --lp-color-red-950: #3c040d;
  --lp-color-black-0: #07080c0d;
  --lp-color-black-50: #07080c1a;
  --lp-color-black-100: #07080c26;
  --lp-color-black-200: #07080c33;
  --lp-color-black-300: #07080c4d;
  --lp-color-black-400: #07080c66;
  --lp-color-black-500: #07080c80;
  --lp-color-black-600: #07080c99;
  --lp-color-black-700: #07080cb3;
  --lp-color-black-800: #07080ccc;
  --lp-color-black-900: #07080ce6;
  --lp-color-black-950: #07080c;
  --lp-color-white-0: #ffffff0d;
  --lp-color-white-50: #ffffff1a;
  --lp-color-white-100: #ffffff26;
  --lp-color-white-200: #fff3;
  --lp-color-white-300: #ffffff4d;
  --lp-color-white-400: #fff6;
  --lp-color-white-500: #ffffff80;
  --lp-color-white-600: #fff9;
  --lp-color-white-700: #ffffffb3;
  --lp-color-white-800: #fffc;
  --lp-color-white-900: #ffffffe6;
  --lp-color-white-950: #fff;
  --lp-color-brand-blue-light: #7084ff;
  --lp-color-brand-blue-base: #405bff;
  --lp-color-brand-blue-dark: #2a3ba6;
  --lp-color-brand-cyan-light: #6de0f7;
  --lp-color-brand-cyan-base: #3dd6f5;
  --lp-color-brand-cyan-dark: #238ca3;
  --lp-color-brand-purple-light: #b675e4;
  --lp-color-brand-purple-base: #a34fde;
  --lp-color-brand-purple-dark: #6a3390;
  --lp-color-brand-pink-light: #ee5dac;
  --lp-color-brand-pink-base: #ff35a2;
  --lp-color-brand-pink-dark: #810b4b;
  --lp-color-brand-orange-light: #ffd099;
  --lp-color-brand-orange-base: #ff9d29;
  --lp-color-brand-orange-dark: #a35901;
  --lp-color-brand-yellow-light: #f7ffaf;
  --lp-color-brand-yellow-base: #ebff38;
  --lp-color-brand-yellow-dark: #818c1f;
  --lp-color-brand-green-light: #e1ffc8;
  --lp-color-brand-green-base: #8bef34;
  --lp-color-brand-green-dark: #439000;
  --lp-duration-0: 0s;
  --lp-duration-100: .1s;
  --lp-duration-150: .15s;
  --lp-duration-200: .2s;
  --lp-duration-250: .25s;
  --lp-duration-300: .3s;
  --lp-duration-350: .35s;
  --lp-duration-400: .4s;
  --lp-duration-500: .5s;
  --lp-font-family-base: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, Arial, sans-serif;
  --lp-font-family-monospace: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
  --lp-font-family-display: "Audimat 3000 Regulier";
  --lp-font-size-800: 3.75rem;
  --lp-font-weight-light: 300;
  --lp-font-weight-regular: 400;
  --lp-font-weight-medium: 500;
  --lp-font-weight-semibold: 600;
  --lp-font-weight-bold: 700;
  --lp-font-weight-extrabold: 800;
  --lp-size-0: 0;
  --lp-size-1: .0625rem;
  --lp-size-2: .125rem;
  --lp-size-3: .1875rem;
  --lp-size-4: .25rem;
  --lp-size-6: .375rem;
  --lp-size-8: .5rem;
  --lp-size-10: .625rem;
  --lp-size-11: .6875rem;
  --lp-size-12: .75rem;
  --lp-size-13: .8125rem;
  --lp-size-14: .875rem;
  --lp-size-15: .9375rem;
  --lp-size-16: 1rem;
  --lp-size-18: 1.125rem;
  --lp-size-20: 1.25rem;
  --lp-size-24: 1.5rem;
  --lp-size-28: 1.75rem;
  --lp-size-32: 2rem;
  --lp-size-36: 2.25rem;
  --lp-size-40: 2.5rem;
  --lp-size-44: 2.75rem;
  --lp-size-48: 3rem;
  --lp-size-56: 3.5rem;
  --lp-size-64: 4rem;
  --lp-size-72: 4.5rem;
  --lp-size-80: 5rem;
  --lp-size-96: 6rem;
  --lp-size-112: 7rem;
  --lp-size-128: 8rem;
  --lp-size-144: 9rem;
  --lp-size-160: 10rem;
  --lp-size-176: 11rem;
  --lp-size-192: 12rem;
  --lp-size-208: 13rem;
  --lp-size-224: 14rem;
  --lp-size-240: 15rem;
  --lp-size-256: 16rem;
  --lp-size-320: 20rem;
  --lp-size-400: 25rem;
  --lp-size-480: 30rem;
  --lp-size-560: 35rem;
  --lp-size-640: 40rem;
  --lp-size-720: 45rem;
  --lp-size-800: 50rem;
  --lp-size-960: 60rem;
  --lp-size-1120: 70rem;
  --lp-size-1200: 75rem;
  --lp-size-1280: 80rem;
  --lp-size-1440: 90rem;
  --lp-viewport-tablet: 46.25rem;
  --lp-viewport-desktop: 62rem;
  --lp-z-index-100: 100;
  --lp-z-index-200: 200;
  --lp-z-index-300: 300;
  --lp-z-index-400: 400;
  --lp-z-index-500: 500;
  --lp-z-index-600: 600;
  --lp-z-index-700: 700;
  --lp-z-index-800: 800;
  --lp-z-index-900: 900;
  --lp-border-radius-regular: var(--lp-size-3);
  --lp-border-radius-medium: var(--lp-size-6);
  --lp-border-radius-large: var(--lp-size-16);
  --lp-border-width-100: var(--lp-size-0);
  --lp-border-width-200: var(--lp-size-1);
  --lp-border-width-300: var(--lp-size-2);
  --lp-font-size-100: var(--lp-size-11);
  --lp-font-size-200: var(--lp-size-13);
  --lp-font-size-300: var(--lp-size-15);
  --lp-font-size-400: var(--lp-size-20);
  --lp-font-size-500: var(--lp-size-24);
  --lp-font-size-600: var(--lp-size-32);
  --lp-font-size-700: var(--lp-size-44);
  --lp-line-height-100: var(--lp-size-16);
  --lp-line-height-200: var(--lp-size-20);
  --lp-line-height-300: var(--lp-size-24);
  --lp-line-height-400: var(--lp-size-28);
  --lp-line-height-500: var(--lp-size-72);
  --lp-gradient-yellow-cyan: linear-gradient(127deg, var(--lp-color-brand-yellow-base) -38.66%, var(--lp-color-brand-cyan-base) 83.73%);
  --lp-gradient-yellow-pink: linear-gradient(148deg, var(--lp-color-brand-yellow-base) 13.5%, var(--lp-color-brand-pink-base) 90.96%);
  --lp-gradient-yellow-blue-pale: linear-gradient(0deg, var(--lp-color-white-50) 0%, var(--lp-color-white-50) 100%), linear-gradient(151deg, var(--lp-color-brand-yellow-light) 5.75%, var(--lp-color-brand-blue-light) 90%);
  --lp-gradient-cyan-blue: linear-gradient(136deg, var(--lp-color-brand-cyan-base) 22.68%, var(--lp-color-brand-blue-base) 127.6%);
  --lp-gradient-cyan-purple: linear-gradient(323deg, var(--lp-color-brand-purple-base) -11.93%, var(--lp-color-brand-cyan-base) 125.4%);
  --lp-gradient-purple-blue: linear-gradient(131deg, var(--lp-color-brand-purple-base) -15.82%, var(--lp-color-brand-blue-base) 118.85%);
  --lp-gradient-purple-pink: linear-gradient(326deg, var(--lp-color-brand-purple-base) -9.86%, var(--lp-color-brand-pink-base) 112.59%);
  --lp-spacing-100: var(--lp-size-0);
  --lp-spacing-200: var(--lp-size-4);
  --lp-spacing-300: var(--lp-size-8);
  --lp-spacing-400: var(--lp-size-12);
  --lp-spacing-500: var(--lp-size-16);
  --lp-spacing-600: var(--lp-size-20);
  --lp-spacing-700: var(--lp-size-24);
  --lp-spacing-800: var(--lp-size-28);
  --lp-spacing-900: var(--lp-size-32);
  --lp-text-heading-1-medium: var(--lp-font-weight-medium) var(--lp-font-size-400) / var(--lp-line-height-400) var(--lp-font-family-base);
  --lp-text-heading-1-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-400) / var(--lp-line-height-400) var(--lp-font-family-base);
  --lp-text-heading-1-extrabold: var(--lp-font-weight-extrabold) var(--lp-font-size-400) / var(--lp-line-height-400) var(--lp-font-family-base);
  --lp-text-heading-2-medium: var(--lp-font-weight-medium) var(--lp-font-size-300) / var(--lp-line-height-300) var(--lp-font-family-base);
  --lp-text-heading-2-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-300) / var(--lp-line-height-300) var(--lp-font-family-base);
  --lp-text-heading-2-extrabold: var(--lp-font-weight-extrabold) var(--lp-font-size-300) / var(--lp-line-height-300) var(--lp-font-family-base);
  --lp-text-heading-3-medium: var(--lp-font-weight-medium) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-heading-3-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-heading-3-extrabold: var(--lp-font-weight-extrabold) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-body-1-regular: var(--lp-font-weight-regular) var(--lp-font-size-300) / var(--lp-line-height-300) var(--lp-font-family-base);
  --lp-text-body-1-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-300) / var(--lp-line-height-300) var(--lp-font-family-base);
  --lp-text-body-1-extrabold: var(--lp-font-weight-extrabold) var(--lp-font-size-300) / var(--lp-line-height-300) var(--lp-font-family-base);
  --lp-text-body-2-regular: var(--lp-font-weight-regular) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-body-2-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-body-2-extrabold: var(--lp-font-weight-extrabold) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-small-1-regular: var(--lp-font-weight-regular) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-base);
  --lp-text-small-1-medium: var(--lp-font-weight-medium) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-base);
  --lp-text-small-1-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-base);
  --lp-text-label-1-regular: var(--lp-font-weight-regular) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-label-1-medium: var(--lp-font-weight-medium) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-label-1-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-base);
  --lp-text-label-2-regular: var(--lp-font-weight-regular) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-base);
  --lp-text-label-2-medium: var(--lp-font-weight-medium) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-base);
  --lp-text-label-2-semibold: var(--lp-font-weight-semibold) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-base);
  --lp-text-code-1-regular: var(--lp-font-weight-regular) var(--lp-font-size-200) / var(--lp-line-height-200) var(--lp-font-family-monospace);
  --lp-text-code-2-regular: var(--lp-font-weight-regular) var(--lp-font-size-100) / var(--lp-line-height-100) var(--lp-font-family-monospace);
  --lp-text-display-1: var(--lp-font-weight-regular) var(--lp-font-size-800) / var(--lp-line-height-500) var(--lp-font-family-display);
  --lp-viewport-mobile: var(--lp-size-0);
  --lp-viewport-wide: var(--lp-size-1200);
}

:root, [data-theme] {
  --lp-color-bg-interactive-tertiary-base: #0000;
  --lp-color-bg-interactive-tertiary-active: #0000;
  --lp-color-border-interactive-primary-base: #0000;
  --lp-color-border-interactive-primary-active: #0000;
  --lp-color-border-interactive-primary-focus: #0000;
  --lp-color-border-interactive-primary-hover: #0000;
  --lp-color-border-interactive-disabled: #0000;
  --lp-color-bg-feedback-primary: var(--lp-color-gray-800);
  --lp-color-bg-feedback-error: var(--lp-color-red-50);
  --lp-color-bg-feedback-info: var(--lp-color-blue-50);
  --lp-color-bg-feedback-success: var(--lp-color-green-50);
  --lp-color-bg-interactive-primary-base: var(--lp-color-blue-500);
  --lp-color-bg-interactive-primary-active: var(--lp-color-blue-500);
  --lp-color-bg-interactive-primary-focus: var(--lp-color-blue-600);
  --lp-color-bg-interactive-primary-hover: var(--lp-color-blue-600);
  --lp-color-bg-interactive-secondary-focus: var(--lp-color-gray-50);
  --lp-color-bg-interactive-secondary-hover: var(--lp-color-gray-50);
  --lp-color-bg-interactive-tertiary-focus: var(--lp-color-gray-50);
  --lp-color-bg-interactive-tertiary-hover: var(--lp-color-gray-50);
  --lp-color-bg-interactive-destructive-base: var(--lp-color-red-500);
  --lp-color-bg-interactive-destructive-active: var(--lp-color-red-500);
  --lp-color-bg-interactive-destructive-focus: var(--lp-color-red-600);
  --lp-color-bg-interactive-destructive-hover: var(--lp-color-red-600);
  --lp-color-bg-interactive-disabled: var(--lp-color-gray-100);
  --lp-color-bg-interactive-link: var(--lp-color-blue-50);
  --lp-color-bg-interactive-selected: var(--lp-color-blue-0);
  --lp-color-bg-ui-primary: var(--lp-color-white-950);
  --lp-color-bg-ui-secondary: var(--lp-color-gray-0);
  --lp-color-bg-ui-tertiary: var(--lp-color-gray-50);
  --lp-color-bg-product-beta: var(--lp-color-purple-100);
  --lp-color-bg-product-federal: var(--lp-color-brand-yellow-base);
  --lp-color-border-feedback-error: var(--lp-color-red-500);
  --lp-color-border-feedback-info: var(--lp-color-blue-500);
  --lp-color-border-feedback-success: var(--lp-color-green-500);
  --lp-color-border-field-active: var(--lp-color-blue-500);
  --lp-color-border-field-focus: var(--lp-color-blue-500);
  --lp-color-border-interactive-focus: var(--lp-color-blue-500);
  --lp-color-border-interactive-selected: var(--lp-color-blue-500);
  --lp-color-border-ui-primary: var(--lp-color-gray-100);
  --lp-color-border-ui-secondary: var(--lp-color-gray-50);
  --lp-color-fill-feedback-error: var(--lp-color-red-500);
  --lp-color-fill-feedback-info: var(--lp-color-blue-500);
  --lp-color-fill-feedback-success: var(--lp-color-green-500);
  --lp-color-fill-interactive-primary: var(--lp-color-white-950);
  --lp-color-fill-interactive-destructive: var(--lp-color-red-500);
  --lp-color-fill-ui-primary: var(--lp-color-gray-700);
  --lp-color-fill-ui-secondary: var(--lp-color-gray-500);
  --lp-color-fill-field-base: var(--lp-color-gray-600);
  --lp-color-shadow-interactive-focus: var(--lp-color-blue-600);
  --lp-color-shadow-interactive-primary: var(--lp-color-white-950);
  --lp-color-shadow-ui-primary: var(--lp-color-black-50);
  --lp-color-shadow-ui-secondary: var(--lp-color-black-0);
  --lp-color-text-feedback-error: var(--lp-color-red-600);
  --lp-color-text-feedback-success: var(--lp-color-green-600);
  --lp-color-text-feedback-info: var(--lp-color-blue-600);
  --lp-color-text-interactive-base: var(--lp-color-blue-600);
  --lp-color-text-interactive-active: var(--lp-color-purple-700);
  --lp-color-text-interactive-primary-base: var(--lp-color-white-950);
  --lp-color-text-interactive-primary-active: var(--lp-color-white-950);
  --lp-color-text-interactive-primary-focus: var(--lp-color-white-950);
  --lp-color-text-interactive-primary-hover: var(--lp-color-white-950);
  --lp-color-text-interactive-secondary: var(--lp-color-gray-600);
  --lp-color-text-interactive-disabled: var(--lp-color-gray-500);
  --lp-color-text-interactive-selected: var(--lp-color-blue-600);
  --lp-color-text-ui-primary-base: var(--lp-color-gray-900);
  --lp-color-text-ui-primary-inverted: var(--lp-color-white-950);
  --lp-color-text-ui-secondary: var(--lp-color-gray-600);
  --lp-color-text-field-disabled: var(--lp-color-gray-500);
  --lp-color-text-field-placeholder: var(--lp-color-gray-500);
  --lp-color-text-product-beta: var(--lp-color-purple-600);
  --lp-color-text-product-federal: var(--lp-color-gray-950);
  --lp-color-text-code-function: var(--lp-color-brand-purple-dark);
  --lp-color-text-code-tag: var(--lp-color-brand-orange-dark);
  --lp-color-text-code-string: var(--lp-color-brand-cyan-dark);
  --lp-color-text-code-comment: var(--lp-color-gray-400);
  --lp-color-text-code-base: var(--lp-color-gray-600);
  --lp-color-text-code-keyword: var(--lp-color-brand-pink-base);
  --lp-color-text-code-title: var(--lp-color-brand-orange-base);
  --lp-color-text-code-number: var(--lp-color-brand-blue-dark);
  --lp-color-bg-interactive-secondary-base: var(--lp-color-bg-ui-primary);
  --lp-color-bg-interactive-secondary-active: var(--lp-color-bg-ui-primary);
  --lp-color-bg-overlay-primary: var(--lp-color-bg-ui-primary);
  --lp-color-bg-overlay-secondary: var(--lp-color-bg-ui-primary);
  --lp-color-bg-field-base: var(--lp-color-bg-ui-primary);
  --lp-color-bg-field-disabled: var(--lp-color-bg-ui-tertiary);
  --lp-color-border-field-base: var(--lp-color-border-ui-primary);
  --lp-color-border-field-error: var(--lp-color-border-feedback-error);
  --lp-color-border-field-disabled: var(--lp-color-border-ui-secondary);
  --lp-color-border-interactive-secondary-base: var(--lp-color-border-ui-primary);
  --lp-color-border-interactive-secondary-active: var(--lp-color-border-ui-primary);
  --lp-color-border-interactive-secondary-focus: var(--lp-color-border-ui-primary);
  --lp-color-border-interactive-secondary-hover: var(--lp-color-border-ui-primary);
  --lp-color-border-interactive-destructive: var(--lp-color-border-feedback-error);
  --lp-color-text-feedback-base: var(--lp-color-text-ui-primary-base);
  --lp-color-text-interactive-destructive: var(--lp-color-text-feedback-error);
}

[data-theme="dark"] {
  --lp-color-bg-interactive-link: #4761ff33;
  --lp-color-bg-feedback-error: var(--lp-color-red-900);
  --lp-color-bg-feedback-info: var(--lp-color-blue-900);
  --lp-color-bg-feedback-success: var(--lp-color-green-900);
  --lp-color-bg-interactive-primary-base: var(--lp-color-blue-600);
  --lp-color-bg-interactive-primary-active: var(--lp-color-blue-600);
  --lp-color-bg-interactive-primary-focus: var(--lp-color-blue-500);
  --lp-color-bg-interactive-primary-hover: var(--lp-color-blue-500);
  --lp-color-bg-interactive-secondary-focus: var(--lp-color-gray-800);
  --lp-color-bg-interactive-secondary-hover: var(--lp-color-gray-800);
  --lp-color-bg-interactive-tertiary-focus: var(--lp-color-gray-800);
  --lp-color-bg-interactive-tertiary-hover: var(--lp-color-gray-800);
  --lp-color-bg-interactive-destructive-base: var(--lp-color-red-600);
  --lp-color-bg-interactive-destructive-active: var(--lp-color-red-600);
  --lp-color-bg-interactive-destructive-focus: var(--lp-color-red-500);
  --lp-color-bg-interactive-destructive-hover: var(--lp-color-red-500);
  --lp-color-bg-interactive-disabled: var(--lp-color-gray-800);
  --lp-color-bg-interactive-selected: var(--lp-color-blue-950);
  --lp-color-bg-ui-primary: var(--lp-color-gray-950);
  --lp-color-bg-ui-secondary: var(--lp-color-gray-900);
  --lp-color-bg-ui-tertiary: var(--lp-color-gray-800);
  --lp-color-bg-product-beta: var(--lp-color-purple-900);
  --lp-color-bg-product-federal: var(--lp-color-brand-yellow-dark);
  --lp-color-border-feedback-error: var(--lp-color-red-400);
  --lp-color-border-feedback-info: var(--lp-color-blue-400);
  --lp-color-border-feedback-success: var(--lp-color-green-400);
  --lp-color-border-field-active: var(--lp-color-blue-400);
  --lp-color-border-field-focus: var(--lp-color-blue-400);
  --lp-color-border-interactive-focus: var(--lp-color-blue-400);
  --lp-color-border-interactive-selected: var(--lp-color-blue-400);
  --lp-color-border-ui-primary: var(--lp-color-gray-700);
  --lp-color-border-ui-secondary: var(--lp-color-gray-800);
  --lp-color-fill-ui-primary: var(--lp-color-gray-200);
  --lp-color-fill-field-base: var(--lp-color-gray-400);
  --lp-color-shadow-interactive-focus: var(--lp-color-blue-400);
  --lp-color-shadow-interactive-primary: var(--lp-color-gray-950);
  --lp-color-shadow-ui-primary: var(--lp-color-black-200);
  --lp-color-shadow-ui-secondary: var(--lp-color-black-100);
  --lp-color-text-feedback-error: var(--lp-color-red-400);
  --lp-color-text-feedback-success: var(--lp-color-green-400);
  --lp-color-text-feedback-info: var(--lp-color-blue-400);
  --lp-color-text-interactive-base: var(--lp-color-blue-400);
  --lp-color-text-interactive-active: var(--lp-color-purple-400);
  --lp-color-text-interactive-secondary: var(--lp-color-gray-400);
  --lp-color-text-interactive-disabled: var(--lp-color-gray-600);
  --lp-color-text-interactive-selected: var(--lp-color-blue-100);
  --lp-color-text-ui-primary-base: var(--lp-color-gray-0);
  --lp-color-text-ui-primary-inverted: var(--lp-color-gray-950);
  --lp-color-text-ui-secondary: var(--lp-color-gray-400);
  --lp-color-text-field-placeholder: var(--lp-color-gray-400);
  --lp-color-text-product-beta: var(--lp-color-purple-400);
  --lp-color-text-code-function: var(--lp-color-brand-purple-base);
  --lp-color-text-code-tag: var(--lp-color-brand-orange-base);
  --lp-color-text-code-string: var(--lp-color-brand-cyan-light);
  --lp-color-text-code-base: var(--lp-color-gray-200);
  --lp-color-text-code-keyword: var(--lp-color-brand-pink-light);
  --lp-color-text-code-title: var(--lp-color-brand-orange-light);
  --lp-color-text-code-number: var(--lp-color-brand-blue-light);
  --lp-color-bg-overlay-secondary: var(--lp-color-bg-ui-secondary);
  --lp-color-bg-field-base: var(--lp-color-bg-ui-secondary);
}

.u-debug * {
  outline: 1px solid gold;
}

.u-assistive {
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.u-truncate.u-truncate-target, .u-truncate .u-truncate-target {
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
}

.u-ff-sans {
  font-family: var(--lp-font-family-base);
}

.u-ff-mono {
  font-family: var(--lp-font-family-monospace);
}

.u-tl {
  text-align: left;
}

.u-tr {
  text-align: right;
}

.u-tc {
  text-align: center;
}

.u-ttc {
  text-transform: capitalize;
}

.u-ttl {
  text-transform: lowercase;
}

.u-ttu {
  text-transform: uppercase;
}

.u-ttn {
  text-transform: none;
}

.u-fs-xs, .u-fs-xs-even {
  font-size: var(--lp-font-size-100);
}

.u-fs-sm, .u-fs-sm-even {
  font-size: var(--lp-font-size-200);
}

.u-fs-md {
  font-size: var(--lp-font-size-300);
}

.u-fs-l, .u-fs-xl {
  font-size: var(--lp-font-size-400);
}

.u-fs-xxl {
  font-size: var(--lp-font-size-600);
}

.u-fs-xxxl {
  font-size: var(--lp-font-size-800);
}

.u-fw-light {
  font-weight: var(--lp-font-weight-light);
}

.u-fw-regular {
  font-weight: var(--lp-font-weight-regular);
}

.u-fw-medium {
  font-weight: var(--lp-font-weight-medium);
}

.u-fw-semibold {
  font-weight: var(--lp-font-weight-semibold);
}

.u-fw-bold {
  font-weight: var(--lp-font-weight-bold);
}

.u-lh-sm {
  line-height: var(--lp-line-height-200);
}

.u-measure {
  max-width: 30em;
}

.u-mw-50 {
  max-width: 50%;
}

.u-textKern {
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}

.u-success, .u-positive {
  color: var(--lp-color-text-feedback-success);
}

.u-success [data-icon], .u-positive [data-icon] {
  fill: var(--lp-color-text-feedback-success);
}

.u-error, .u-negative, .u-dangerous {
  color: var(--lp-color-text-feedback-error);
}

.u-error [data-icon], .u-negative [data-icon], .u-dangerous [data-icon] {
  fill: var(--lp-color-text-feedback-error);
}

.u-warning {
  color: var(--lp-color-brand-yellow-dark);
}

.u-warning[data-icon] {
  fill: var(--lp-color-brand-yellow-dark);
}

.u-strike {
  text-decoration: line-through;
}

.u-underline {
  text-decoration: underline;
}

.u-subtle, .u-very-subtle {
  color: var(--lp-color-text-ui-secondary);
  fill: var(--lp-color-text-ui-secondary);
}

.u-currentColor {
  color: currentColor;
}

.u-voice {
  color: var(--lp-color-text-ui-secondary);
  font-style: italic;
}

.u-italic {
  font-style: italic;
}

.u-prose p, .u-prose .Snippet {
  margin-top: .5em;
  margin-bottom: 1em;
}

.u-prose p:last-child, .u-prose .Snippet:last-child {
  margin-bottom: 0;
}

.u-list-lower-alpha {
  list-style-type: lower-alpha;
}

.u-list-none {
  list-style: none;
}

.u-no-wrap {
  white-space: nowrap;
}

:root[data-type-refresh] {
  & .u-fs-xs, & .u-fs-xs-even {
    font: var(--lp-text-small-1-regular);
  }

  & .u-fs-sm, & .u-fs-sm-even {
    font: var(--lp-text-body-2-regular);
  }

  & .u-fs-md {
    font: var(--lp-text-body-1-regular);
  }

  & .u-fs-l, & .u-fs-xl {
    font: var(--lp-text-heading-1-medium);
  }

  & .u-fs-xxl, & .u-fs-xxxl {
    font: var(--lp-text-heading-1-semibold);
  }
}

.u-color-text-ui-primary {
  color: var(--lp-color-text-ui-primary-base);
}

.u-color-text-ui-secondary, .u-color-text-ui-tertiary {
  color: var(--lp-color-text-ui-secondary);
}

.u-white {
  color: var(--lp-color-white-950);
}

.u-red {
  color: var(--lp-color-text-feedback-error);
}

.u-dark-green[data-icon] {
  fill: var(--lp-color-text-feedback-success);
}

.u-danger-red-dark {
  color: var(--lp-color-text-feedback-error);
}

.u-color-bg-ui-secondary {
  background-color: var(--lp-color-bg-ui-secondary) !important;
}

.u-v-baseline {
  vertical-align: baseline;
}

.u-v-middle {
  vertical-align: middle;
}

.u-v-top {
  vertical-align: top;
}

.u-v-bottom {
  vertical-align: bottom;
}

.u-align-content-center {
  align-content: center;
}

@keyframes pulse {
  0%, 80%, 100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate3d(0, 0, 1, 0);
  }

  100% {
    transform: rotate3d(0, 0, 1, 359deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.u-animated {
  animation-duration: 1s;
}

.u-animated.u-infinite {
  animation-iteration-count: infinite;
}

.u-pulse {
  animation-name: pulse;
}

.u-spin {
  animation-name: spin;
}

.u-flash {
  animation-name: flash;
}

.u-fade-in {
  animation-name: fadeIn;
  animation-duration: .7s;
}

.u-fade-out {
  opacity: 0;
  height: 0;
  animation-name: fadeOut;
  animation-duration: .3s;
}

.u-flex {
  display: flex;
}

.u-flex-auto {
  flex: auto;
}

.u-flex-none {
  flex: none;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-row {
  flex-direction: row;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-stretch {
  align-items: stretch;
}

.u-flex-top {
  align-items: flex-start;
}

.u-flex-middle {
  align-items: center;
}

.u-flex-bottom {
  align-items: flex-end;
}

.u-flex-baseline {
  align-items: baseline;
}

.u-flex-start {
  text-align: start;
  justify-content: flex-start;
}

.u-flex-center {
  text-align: center;
  justify-content: center;
}

.u-flex-end {
  text-align: end;
  justify-content: flex-end;
}

.u-flex-around {
  justify-content: space-around;
}

.u-flex-between {
  justify-content: space-between;
}

.u-flex-evenly {
  justify-content: space-evenly;
}

.u-grow-0 {
  flex-grow: 0;
}

.u-grow-half {
  flex-grow: .5;
}

.u-grow-1 {
  flex-grow: 1;
}

.u-grow-2 {
  flex-grow: 2;
}

.u-grow-3 {
  flex-grow: 3;
}

.u-shrink-0 {
  flex-shrink: 0;
}

.u-shrink-1 {
  flex-shrink: 1;
}

.u-shrink-2 {
  flex-shrink: 2;
}

.u-shrink-3 {
  flex-shrink: 3;
}

.u-order-1 {
  order: 1;
}

.u-order-2 {
  order: 2;
}

.u-order-3 {
  order: 3;
}

.u-pullleft {
  margin-right: auto;
}

.u-pullright {
  margin-left: auto;
}

.u-pullbottom {
  margin-top: auto;
}

.u-col2 {
  columns: 2;
}

.u-col3 {
  columns: 3;
}

.u-col2 > *, .u-col3 > * {
  break-inside: avoid;
}

.u-gap-0 {
  gap: var(--lp-spacing-100);
}

.u-gap-1 {
  gap: var(--lp-spacing-200);
}

.u-gap-2 {
  gap: var(--lp-spacing-300);
}

.u-gap-3 {
  gap: var(--lp-spacing-400);
}

.u-gap-4 {
  gap: var(--lp-spacing-500);
}

.u-gap-5 {
  gap: var(--lp-spacing-600);
}

.u-gap-6 {
  gap: var(--lp-spacing-700);
}

.u-gap-7 {
  gap: var(--lp-spacing-800);
}

.u-gap-8 {
  gap: var(--lp-spacing-900);
}

.u-w1 {
  width: .625rem;
}

.u-w2 {
  width: 1.25rem;
}

.u-w3 {
  width: 1.875rem;
}

.u-w4 {
  width: 2.5rem;
}

.u-4-1 {
  width: 5rem;
}

.u-4-2 {
  width: 8.125rem;
}

.u-w5 {
  width: 10rem;
}

.u-w6 {
  width: 20rem;
}

.u-w7 {
  width: 40rem;
}

.u-w-10 {
  width: 10%;
}

.u-w-20 {
  width: 20%;
}

.u-w-25 {
  width: 25%;
}

.u-w-30 {
  width: 30%;
}

.u-w-33 {
  width: 33%;
}

.u-w-40 {
  width: 40%;
}

.u-w-45 {
  width: 45%;
}

.u-w-50 {
  width: 50%;
}

.u-w-60 {
  width: 60%;
}

.u-w-75 {
  width: 75%;
}

.u-w-80 {
  width: 80%;
}

.u-w-100 {
  width: 100%;
}

.u-w-auto {
  width: auto;
}

@media screen and (width >= 0) {
  .u-xs-w1 {
    width: .625rem;
  }

  .u-xs-w2 {
    width: 1.25rem;
  }

  .u-xs-w3 {
    width: 1.875rem;
  }

  .u-xs-w4 {
    width: 2.5rem;
  }

  .u-xs-w5 {
    width: 10rem;
  }

  .u-xs-w6 {
    width: 20rem;
  }

  .u-xs-w-10 {
    width: 10%;
  }

  .u-xs-w-20 {
    width: 20%;
  }

  .u-xs-w-25 {
    width: 25%;
  }

  .u-xs-w-33 {
    width: 33%;
  }

  .u-xs-w-34 {
    width: 34%;
  }

  .u-xs-w-40 {
    width: 40%;
  }

  .u-xs-w-50 {
    width: 50%;
  }

  .u-xs-w-60 {
    width: 60%;
  }

  .u-xs-w-75 {
    width: 75%;
  }

  .u-xs-w-80 {
    width: 80%;
  }

  .u-xs-w-100 {
    width: 100%;
  }

  .u-xs-w-auto {
    width: auto;
  }
}

@media screen and (width >= 46.25rem) {
  .u-sm-w1 {
    width: .625rem;
  }

  .u-sm-w2 {
    width: 1.25rem;
  }

  .u-sm-w3 {
    width: 1.875rem;
  }

  .u-sm-w4 {
    width: 2.5rem;
  }

  .u-sm-w5 {
    width: 10rem;
  }

  .u-sm-w6 {
    width: 20rem;
  }

  .u-sm-w-10 {
    width: 10%;
  }

  .u-sm-w-20 {
    width: 20%;
  }

  .u-sm-w-25 {
    width: 25%;
  }

  .u-sm-w-33 {
    width: 33%;
  }

  .u-sm-w-34 {
    width: 34%;
  }

  .u-sm-w-40 {
    width: 40%;
  }

  .u-sm-w-50 {
    width: 50%;
  }

  .u-sm-w-60 {
    width: 60%;
  }

  .u-sm-w-75 {
    width: 75%;
  }

  .u-sm-w-80 {
    width: 80%;
  }

  .u-sm-w-100 {
    width: 100%;
  }

  .u-sm-w-auto {
    width: auto;
  }
}

@media screen and (width >= 62rem) {
  .u-md-w1 {
    width: .625rem;
  }

  .u-md-w2 {
    width: 1.25rem;
  }

  .u-md-w3 {
    width: 1.875rem;
  }

  .u-md-w4 {
    width: 2.5rem;
  }

  .u-md-w5 {
    width: 10rem;
  }

  .u-md-w6 {
    width: 20rem;
  }

  .u-md-w-10 {
    width: 10%;
  }

  .u-md-w-20 {
    width: 20%;
  }

  .u-md-w-25 {
    width: 25%;
  }

  .u-md-w-33 {
    width: 33%;
  }

  .u-md-w-34 {
    width: 34%;
  }

  .u-md-w-40 {
    width: 40%;
  }

  .u-md-w-50 {
    width: 50%;
  }

  .u-md-w-60 {
    width: 60%;
  }

  .u-md-w-75 {
    width: 75%;
  }

  .u-md-w-80 {
    width: 80%;
  }

  .u-md-w-100 {
    width: 100%;
  }

  .u-md-w-auto {
    width: auto;
  }
}

@media screen and (width >= 75rem) {
  .u-lg-w1 {
    width: .625rem;
  }

  .u-lg-w2 {
    width: 1.25rem;
  }

  .u-lg-w3 {
    width: 1.875rem;
  }

  .u-lg-w4 {
    width: 2.5rem;
  }

  .u-lg-w5 {
    width: 10rem;
  }

  .u-lg-w6 {
    width: 20rem;
  }

  .u-lg-w-10 {
    width: 10%;
  }

  .u-lg-w-20 {
    width: 20%;
  }

  .u-lg-w-25 {
    width: 25%;
  }

  .u-lg-w-33 {
    width: 33%;
  }

  .u-lg-w-34 {
    width: 34%;
  }

  .u-lg-w-40 {
    width: 40%;
  }

  .u-lg-w-50 {
    width: 50%;
  }

  .u-lg-w-60 {
    width: 60%;
  }

  .u-lg-w-75 {
    width: 75%;
  }

  .u-lg-w-80 {
    width: 80%;
  }

  .u-lg-w-100 {
    width: 100%;
  }

  .u-lg-w-auto {
    width: auto;
  }
}

.u-mw-100 {
  max-width: 100%;
}

.u-mw1 {
  max-width: .625rem;
}

.u-mw2 {
  max-width: 1.25rem;
}

.u-mw3 {
  max-width: 2.5rem;
}

.u-mw4 {
  max-width: 5rem;
}

.u-mw5 {
  max-width: 10rem;
}

.u-mw6 {
  max-width: 20rem;
}

.u-mw7 {
  max-width: 30rem;
}

.u-mw8 {
  max-width: 40rem;
}

.u-mw9 {
  max-width: 60rem;
}

.u-mw-fitcontent {
  max-width: fit-content;
}

@media screen and (width >= 0) {
  .u-xs-mw-100 {
    max-width: 100%;
  }

  .u-xs-mw1 {
    max-width: .625rem;
  }

  .u-xs-mw2 {
    max-width: 1.25rem;
  }

  .u-xs-mw3 {
    max-width: 2.5rem;
  }

  .u-xs-mw4 {
    max-width: 5rem;
  }

  .u-xs-mw5 {
    max-width: 10rem;
  }

  .u-xs-mw6 {
    max-width: 20rem;
  }

  .u-xs-mw7 {
    max-width: 30rem;
  }

  .u-xs-mw8 {
    max-width: 40rem;
  }

  .u-xs-mw9 {
    max-width: 60rem;
  }
}

@media screen and (width >= 46.25rem) {
  .u-sm-mw-100 {
    max-width: 100%;
  }

  .u-sm-mw1 {
    max-width: .625rem;
  }

  .u-sm-mw2 {
    max-width: 1.25rem;
  }

  .u-sm-mw3 {
    max-width: 2.5rem;
  }

  .u-sm-mw4 {
    max-width: 5rem;
  }

  .u-sm-mw5 {
    max-width: 10rem;
  }

  .u-sm-mw6 {
    max-width: 20rem;
  }

  .u-sm-mw7 {
    max-width: 30rem;
  }

  .u-sm-mw8 {
    max-width: 40rem;
  }

  .u-sm-mw9 {
    max-width: 60rem;
  }
}

@media screen and (width >= 62rem) {
  .u-md-mw-100 {
    max-width: 100%;
  }

  .u-md-mw1 {
    max-width: .625rem;
  }

  .u-md-mw2 {
    max-width: 1.25rem;
  }

  .u-md-mw3 {
    max-width: 2.5rem;
  }

  .u-md-mw4 {
    max-width: 5rem;
  }

  .u-md-mw5 {
    max-width: 10rem;
  }

  .u-md-mw6 {
    max-width: 20rem;
  }

  .u-md-mw7 {
    max-width: 30rem;
  }

  .u-md-mw8 {
    max-width: 40rem;
  }

  .u-md-mw9 {
    max-width: 60rem;
  }
}

@media screen and (width >= 75rem) {
  .u-lg-mw-100 {
    max-width: 100%;
  }

  .u-lg-mw1 {
    max-width: .625rem;
  }

  .u-lg-mw2 {
    max-width: 1.25rem;
  }

  .u-lg-mw3 {
    max-width: 2.5rem;
  }

  .u-lg-mw4 {
    max-width: 5rem;
  }

  .u-lg-mw5 {
    max-width: 10rem;
  }

  .u-lg-mw6 {
    max-width: 20rem;
  }

  .u-lg-mw7 {
    max-width: 30rem;
  }

  .u-lg-mw8 {
    max-width: 40rem;
  }

  .u-lg-mw9 {
    max-width: 60rem;
  }
}

.u-mh-40 {
  max-height: 40vh;
}

.u-mh-50 {
  max-height: 50vh;
}

.u-h-0 {
  height: 0;
}

.u-ma-l {
  margin: 1.25rem;
}

.u-mb-l {
  margin-bottom: 1.25rem;
}

.u-mb-m {
  margin-bottom: .625rem;
}

.u-mb-s {
  margin-bottom: .3125rem;
}

.u-mb-xl {
  margin-bottom: 2.5rem;
}

.u-mb-xs {
  margin-bottom: .15625rem;
}

.u-mh-0 {
  border: none !important;
  margin: 0 !important;
}

.u-mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-mh-l {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.u-mh-m {
  margin-left: .625rem;
  margin-right: .625rem;
}

.u-mh-s {
  margin-left: .3125rem;
  margin-right: .3125rem;
}

.u-mh-xs {
  margin-left: .15625rem;
  margin-right: .15625rem;
}

.u-ml-auto {
  margin-left: auto;
}

.u-ml-l {
  margin-left: 1.25rem;
}

.u-ml-m {
  margin-left: .625rem;
}

.u-ml-s {
  margin-left: .3125rem;
}

.u-ml-xl {
  margin-left: 2.5rem;
}

.u-ml-xs {
  margin-left: .15625rem;
}

.u-mr-l {
  margin-right: 1.25rem;
}

.u-mr-m {
  margin-right: .625rem;
}

.u-mr-s {
  margin-right: .3125rem;
}

.u-mr-xl {
  margin-right: 2.5rem;
}

.u-mr-xs {
  margin-right: .15625rem;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-l {
  margin-top: 1.25rem;
}

.u-mt-m {
  margin-top: .625rem;
}

.u-mt-s {
  margin-top: .3125rem;
}

.u-mt-xl {
  margin-top: 2.5rem;
}

.u-mt-xs {
  margin-top: .15625rem;
}

.u-mv-l {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.u-mv-m {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.u-mv-s {
  margin-top: .3125rem;
  margin-bottom: .3125rem;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-l {
  padding: 1.25rem;
}

.u-pa-m {
  padding: .625rem;
}

.u-pa-s {
  padding: .3125rem;
}

.u-pa-xl {
  padding: 2.5rem;
}

.u-pa-xs {
  padding: .15625rem;
}

.u-pb-l {
  padding-bottom: 1.25rem;
}

.u-pb-m {
  padding-bottom: .625rem;
}

.u-pb-s {
  padding-bottom: .3125rem;
}

.u-pb-xs {
  padding-bottom: .15625rem;
}

.u-ph-l {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.u-ph-m {
  padding-left: .625rem;
  padding-right: .625rem;
}

.u-ph-s {
  padding-left: .3125rem;
  padding-right: .3125rem;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-l {
  padding-left: 1.25rem;
}

.u-pl-m {
  padding-left: .625rem;
}

.u-pl-s {
  padding-left: .3125rem;
}

.u-pl-xs {
  padding-left: .15625rem;
}

.u-pr-m {
  padding-right: .625rem;
}

.u-pr-s {
  padding-right: .3125rem;
}

.u-pr-xs {
  padding-right: .15625rem;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-l {
  padding-top: 1.25rem;
}

.u-pt-m {
  padding-top: .625rem;
}

.u-pt-s {
  padding-top: .3125rem;
}

.u-pt-xs {
  padding-top: .15625rem;
}

.u-pv-l {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.u-pv-m {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.u-pv-s {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.u-pv-xs {
  padding-top: .15625rem;
  padding-bottom: .15625rem;
}

@media screen and (width >= 0) {
  .u-xs-mr-m {
    margin-right: .625rem;
  }

  .u-xs-mr-s {
    margin-right: .3125rem;
  }

  .u-xs-mr-xs {
    margin-right: .15625rem;
  }

  .u-xs-pb-l {
    padding-bottom: 1.25rem;
  }

  .u-xs-pb-m {
    padding-bottom: .625rem;
  }

  .u-xs-ph-m {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .u-xs-pl-m {
    padding-left: .625rem;
  }

  .u-xs-pl-s {
    padding-left: .3125rem;
  }

  .u-xs-pr-xl {
    padding-right: 2.5rem;
  }

  .u-xs-pt-l {
    padding-top: 1.25rem;
  }

  .u-xs-pv-l {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

@media screen and (width >= 46.25rem) {
  .u-sm-mb-s {
    margin-bottom: .3125rem;
  }

  .u-sm-ml-l {
    margin-left: 1.25rem;
  }

  .u-sm-mt-m {
    margin-top: .625rem;
  }

  .u-sm-pl-l {
    padding-left: 1.25rem;
  }

  .u-sm-pl-m {
    padding-left: .625rem;
  }
}

@media screen and (width >= 62rem) {
  .u-md-mh-xs {
    margin-left: .15625rem;
    margin-right: .15625rem;
  }

  .u-md-pv-m {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }
}

@media screen and (width >= 75rem) {
  .u-lg-mr-xl {
    margin-right: 2.5rem;
  }

  .u-lg-mv-m {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }
}

:root {
  --border-width: 1px;
}

.u-b--lp-color-gray-700 {
  border-color: var(--lp-color-gray-700);
}

.u-b--ui-secondary {
  border-color: var(--lp-color-border-ui-secondary);
}

.u-b--gray-wash {
  border-color: var(--lp-color-bg-ui-secondary);
}

.u-b--lp-color-white-950 {
  border-color: var(--lp-color-white-950);
}

.u-b--lp-color-gray-500 {
  border-color: var(--lp-color-gray-500);
}

.u-b--ui-primary {
  border-color: var(--lp-color-border-ui-primary);
}

.u-b--error {
  border: 1px solid var(--lp-color-border-feedback-error);
}

.u-ba {
  border-style: solid;
  border-width: var(--border-width);
}

.u-bt {
  border-top-style: solid;
  border-top-width: var(--border-width);
}

.u-br {
  border-right-style: solid;
  border-right-width: var(--border-width);
}

.u-bb {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width);
}

.u-bl {
  border-left-style: solid;
  border-left-width: var(--border-width);
}

.u-bn {
  border-style: solid;
  border-width: 0;
}

.u-hidden-border {
  border: hidden;
}

.u-border-none {
  border-radius: none !important;
  border: none !important;
}

@media screen and (width >= 0) {
  .u-xs-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-xs-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-xs-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-xs-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-xs-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-xs-bn {
    border-style: solid;
    border-width: 0;
  }
}

@media screen and (width >= 46.25rem) {
  .u-sm-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-sm-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-sm-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-sm-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-sm-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-sm-bn {
    border-style: solid;
    border-width: 0;
  }
}

@media screen and (width >= 62rem) {
  .u-md-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-md-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-md-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-md-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-md-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-md-bn {
    border-style: solid;
    border-width: 0;
  }
}

@media screen and (width >= 75rem) {
  .u-lg-ba {
    border-style: solid;
    border-width: var(--border-width);
  }

  .u-lg-bt {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .u-lg-br {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .u-lg-bb {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .u-lg-bl {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .u-lg-bn {
    border-style: solid;
    border-width: 0;
  }
}

.u-br-100 {
  border-radius: 100%;
}

.u-br-10 {
  border-radius: 10%;
}

.u-border-radius-regular {
  border-radius: var(--lp-border-radius-regular);
}

.u-border-radius-medium {
  border-radius: var(--lp-border-radius-medium);
}

.u-br--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.u-br--top {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.u-br--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.u-br--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.u-d-none {
  display: none;
}

.u-d-block {
  display: block;
}

.u-d-inlineblock {
  display: inline-block;
}

.u-d-inline {
  display: inline;
}

.u-d-grid {
  display: grid;
}

.u-d-grip-gap-s-5 {
  grid-gap: .9375rem;
}

@media screen and (width >= 0) {
  .u-xs-d-block {
    display: block;
  }

  .u-xs-d-inlineblock {
    display: inline-block;
  }

  .u-xs-d-inline {
    display: inline;
  }
}

@media screen and (width >= 46.25rem) {
  .u-sm-d-block {
    display: block;
  }

  .u-sm-d-inlineblock {
    display: inline-block;
  }

  .u-sm-d-inline {
    display: inline;
  }
}

@media screen and (width >= 62rem) {
  .u-md-d-block {
    display: block;
  }

  .u-md-d-inlineblock {
    display: inline-block;
  }

  .u-md-d-inline {
    display: inline;
  }
}

@media screen and (width >= 75rem) {
  .u-lg-d-block {
    display: block;
  }

  .u-lg-d-inlineblock {
    display: inline-block;
  }

  .u-lg-d-inline {
    display: inline;
  }
}

.u-ws-normal {
  white-space: normal;
}

.u-ws-nowrap {
  white-space: nowrap;
}

.u-ws-pre {
  white-space: pre;
}

.u-wb-normal {
  word-break: normal;
}

.u-wb-break {
  word-break: break-all;
}

.u-wb-word {
  word-break: break-word;
}

.u-o-auto {
  overflow: auto;
}

.u-o-hidden {
  overflow: hidden;
}

.u-o-scroll {
  overflow: scroll;
}

.u-ow-break {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.u-to-clip {
  text-overflow: clip;
}

.u-to-ellipsis {
  text-overflow: ellipsis;
}

.u-a-0 {
  opacity: 0;
}

.u-a-10 {
  opacity: .1;
}

.u-a-20 {
  opacity: .2;
}

.u-a-30 {
  opacity: .3;
}

.u-a-40 {
  opacity: .4;
}

.u-a-50 {
  opacity: .5;
}

.u-a-60 {
  opacity: .6;
}

.u-a-70 {
  opacity: .7;
}

.u-a-80 {
  opacity: .8;
}

.u-a-90 {
  opacity: .9;
}

.u-a-100 {
  opacity: 1;
}

.u-c-pointer {
  cursor: pointer;
}

.u-c-not-allowed {
  cursor: not-allowed;
}

.u-tiny {
  width: .625rem;
  height: .625rem;
}

.u-small {
  width: 1rem;
  height: 1rem;
}

.u-medium {
  width: 1.5rem;
  height: 1.5rem;
}

.u-large {
  width: 2.5rem;
  height: 2.5rem;
}

.u-resize-none {
  resize: none;
}

.u-resize-both {
  resize: both;
}

.u-resize-v {
  resize: vertical;
}

.u-resize-h {
  resize: horizontal;
}

.u-invisible-password {
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.osmo-button {
  background-image: linear-gradient(315deg, #793aa6 0%, #7a3eb1 11%, #7741b9 22%, #7342c2 33%, #7147cd 44%, #6f4cd6 56%, #664de0 67%, #5c52eb 78%, #5157f5 89%, #425bff 100%);
  border: #0000 !important;
}

.osmo-button__wrapper {
  align-items: center;
  gap: var(--lp-spacing-300);
  flex-direction: row;
  display: flex;
}

.osmo-button__icon {
  display: flex;
}

.osmo-button__icon svg path {
  fill: var(--lp-color-white-950);
  display: flex;
}

.spacer-200 {
  margin: var(--lp-spacing-200);
  display: inline-block;
}

.spacer-300 {
  margin: var(--lp-spacing-300);
  display: inline-block;
}

.hint {
  color: var(--lp-color-text-ui-secondary);
  font-size: var(--lp-font-size-200);
}

.hint a {
  color: inherit;
}

.empty-state {
  color: var(--lp-color-text-ui-secondary);
}

.truncate-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
}

.titlecase {
  text-transform: capitalize;
}

.pull-left {
  margin-inline-end: auto;
}

.pull-right {
  margin-inline-start: auto;
}

[data-line-clamp="2"] {
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

html {
  font-size: 100%;
}

body {
  font-family: var(--lp-font-family-base);
  font-size: var(--lp-font-size-300);
  line-height: var(--lp-line-height-300);
  color: var(--lp-color-text-ui-primary-base);
}

h1, h2, h3, h4 {
  font-weight: var(--lp-font-weight-medium);
}

h1, h2, h3 {
  color: var(--lp-color-text-ui-primary-base);
}

h1 {
  font-size: var(--lp-font-size-500);
  line-height: var(--lp-line-height-500);
  margin-bottom: 8px;
}

h2 {
  font-size: var(--lp-font-size-400);
  line-height: var(--lp-line-height-400);
}

h3 {
  font-size: var(--lp-font-size-300);
  line-height: var(--lp-line-height-300);
}

h4 {
  font-size: var(--lp-font-size-200);
  font-weight: var(--lp-font-weight-semibold);
  line-height: var(--lp-line-height-300);
  color: var(--lp-color-text-ui-secondary);
}

a {
  color: var(--lp-color-text-interactive-base);
  border-radius: var(--lp-border-radius-regular);
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:focus-visible:not([data-focus-visible]):not([data-focused]):not([data-pressed]) {
  box-shadow: var(--link-box-shadow-focus);
  outline: none;
}

a.u-subtle {
  color: var(--subtle-link-color);
  text-decoration: none;
}

a.u-subtle:hover {
  color: var(--lp-color-text-ui-primary-base);
  text-decoration: underline;
}

a[href^="tel:"] {
  text-decoration: none;
}

pre {
  font-family: var(--lp-font-family-monospace);
  margin: 0;
  padding: 0;
  font-size: .875rem;
}

code {
  background-color: var(--lp-color-bg-ui-secondary);
  border: 1px solid var(--lp-color-border-ui-primary);
  font-family: var(--lp-font-family-monospace);
  color: var(--lp-color-text-code-keyword);
  border-radius: 2px;
  padding: .125rem .25rem;
  font-size: 87.5%;
}

[data-theme="dark"] mark {
  color: #fff;
  background-color: #767200;
}

abbr {
  cursor: help;
  border-bottom: 1px dotted var(--lp-color-border-ui-secondary);
}

kbd {
  font-family: var(--lp-font-family-monospace);
  font-size: var(--lp-font-size-200);
  color: #555;
  vertical-align: middle;
  border: solid .0625rem var(--lp-color-border-ui-primary);
  background-color: #fcfcfc;
  border-radius: .375rem;
  padding: .3125rem .3125rem .25rem;
  line-height: .625rem;
  display: inline-block;
  box-shadow: inset 0 -.0625rem #bbb;
}

small {
  font-size: var(--lp-font-size-200);
  font-weight: var(--lp-font-weight-regular);
  line-height: var(--lp-line-height-300);
  color: var(--lp-color-text-ui-secondary);
}

strong, b {
  font-weight: var(--lp-font-weight-semibold);
}

svg {
  & text {
    font-family: var(--lp-font-family-base);
  }
}

html.theme-transition, html.theme-transition *, html.theme-transition :before, html.theme-transition :after {
  transition-delay: 0;
  transition-property: background, background-color, border-color;
  transition-duration: .5s;
  transition-timing-function: ease;
}

del {
  color: var(--lp-color-black-950);
}

.IconSize-xlarge {
  width: 2.8125rem;
  height: 2.8125rem;
}

:root[data-type-refresh] {
  & h1 {
    font: var(--lp-text-heading-1-semibold);
  }

  & h2 {
    font: var(--lp-text-heading-2-semibold);
  }

  & h3 {
    font: var(--lp-text-heading-3-semibold);
  }

  & body {
    font: var(--lp-text-body-1-regular);
  }

  & small {
    font: var(--lp-text-small-1-regular);
  }

  & code {
    font: var(--lp-text-code-1-regular);
  }
}

[data-theme] {
  color: var(--lp-color-text-ui-primary-base);
}
